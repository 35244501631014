import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { users } from '../data/app-data';
import { User } from '../models/user.model';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;
    fromCompany: boolean = false;
    headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    isUserLoggedIn: boolean;
    isBrowser;
    allUsers = users;

    options = {
        headers: this.headers
    }
    private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    get isLoggedIn() {
        this.isBrowser = isPlatformBrowser(this.platformId);
        if (this.isBrowser) {
            this.loggedIn = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('isLogin') || null));//.asObservable();
            return this.loggedIn.asObservable();
        }
    }
    constructor(@Inject(PLATFORM_ID) private platformId,
        private http: HttpClient) {
        this.isBrowser = isPlatformBrowser(this.platformId);
        if (this.isBrowser) {
            this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser') || null));
            this.currentUser = this.currentUserSubject.asObservable();
            this.isUserLoggedIn = (JSON.parse(localStorage.getItem('isLogin') || null));
        }

    }

    public get currentUserValue() {
        if (this.currentUserSubject != undefined) {
            return this.currentUserSubject.value;
        }
    }

    login(username, password) {
        let user: User = this.allUsers.find(x => x.email === username);
        if (user.password === password) {
            localStorage.setItem('currentUser', JSON.stringify(user || null));
            localStorage.setItem('isLogin', JSON.stringify(true || null));
            this.isUserLoggedIn = true;
            this.loggedIn.next(true);
            this.currentUserSubject.next(user);
            return user;
        } else {
            return null;
        }
    }

    logout() {
        // remove user from local storage and set current user to null
        this.isUserLoggedIn = false;
        if (this.isBrowser) {
            localStorage.removeItem('currentUser');
            localStorage.removeItem('isLogin');
        }
        this.loggedIn.next(false);
        this.currentUserSubject.next(null);
    }

}